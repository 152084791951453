export const apiUrl = process.env.NEXT_PUBLIC_API_URL || '';
export const contentfulAccessToken = process.env.CONTENTFUL_ACCESS_TOKEN || '';
export const contentfulEnvironment = process.env.CONTENTFUL_ENVIRONMENT || 'develop';
export const contentfulSpaceId = process.env.CONTENTFUL_SPACE_ID || '';
export const fullStoryOrganizationId = process.env.NEXT_PUBLIC_FULLSTORY_ORGANIZATION_ID || '';
export const googleAnalyticsID = process.env.NEXT_PUBLIC_GA_ID || '';
export const googleApiKey = process.env.GOOGLE_API_KEY || '';
export const googleTagManagerID = process.env.NEXT_PUBLIC_GTM_ID || '';
export const healthieApiKey = process.env.HEALTHIE_API_KEY || '';
export const healthieDietitianId = process.env.HEALTHIE_DIETITIAN_ID || '';
export const healthieStripeKey = process.env.NEXT_PUBLIC_HEALTHIE_STRIPE_KEY || '';
export const isBlogFeatureFlagEnabled = process.env.NEXT_PUBLIC_FEATURE_FLAG_BLOG_PAGE === 'true';
export const isCostPlusPageFeatureFlagEnabled =
  process.env.NEXT_PUBLIC_FEATURE_FLAG_COSTPLUS_PAGE === 'true';
export const isSearchEngineIndexable = process.env.SEARCH_ENGINE_INDEXABLE || 'false';
export const nodeEnvironment = process.env.NODE_ENV || 'development';
export const sentryDns = process.env.NEXT_PUBLIC_SENTRY_DSN || '';
export const healthieApiEndpoint = process.env.HEALTHIE_API_ENDPOINT || '';
export const cloudwatchAccessKey = process.env.CLOUDWATCH_ACCESS_KEY || '';
export const cloudwatchSecretKey = process.env.CLOUDWATCH_SECRET_KEY || '';
export const cloudwatchRegion = process.env.CLOUDWATCH_REGION || '';
export const cloudwatchLogGroup = process.env.CLOUDWATCH_LOG_GROUP || '';
export const cloudwatchLogStream = process.env.CLOUDWATCH_LOG_STREAM || '';
