var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"8a44edac5b416153833191d3ad705e77abc2223f"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import { BrowserTracing } from '@sentry/tracing';

import { sentryDns } from './src/env';

Sentry.init({
  dsn: sentryDns,
  tracesSampleRate: 0.2,
  integrations: [new BrowserTracing()],
});
